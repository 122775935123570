<template>
  <div class="wrap">
    <div class="tip">输入文章链接一键提取文章内容</div>
    <p class="platform">支持平台：知乎</p>
    <van-field
      rows="4"
      type="textarea"
      style="margin-bottom: 20px"
      v-model="urlValue"
    >
    </van-field>
    <div class="btn-wrap">
      <van-button
        type="primary"
        color="#ff784c"
        block
        @click="getCopy"
        :loading="loading"
        loading-text="提取中..."
        style="margin-right: 20px"
        >提取文章内容</van-button
      >
      <van-button type="default" block @click="_copy(textValue)"
        >复制文章内容</van-button
      >
    </div>
    <div v-if="zhihuUrl" class="link">
      <p @click="toZhihu">打开知乎内容复制页面</p>
    </div>
    <div>
      <div class="book-content" v-html="textValue || emptyText"></div>
    </div>
    <!-- <span @click="getCopy">看看获取的粘贴</span>
    <span>--</span>
    <span @click="toCopy">看看设置粘贴</span> -->
    <TabBar></TabBar>
  </div>
</template>

<script setup>
import { geCopyContent } from "@/api/public";
import TabBar from "@/components/tabBar.vue";
import { ref } from "vue";
import { Toast } from "vant";
import useClipboard from "vue-clipboard3";

const { toClipboard } = useClipboard();
const urlValue = ref("");
const textValue = ref("");
const loading = ref(false);
const zhihuUrl = ref("");
function toCopy() {
  const clipPromise = navigator.clipboard.readText();
  clipPromise.then(function (clipText) {
    urlValue.value = clipText;
  });
}
const emptyText = `
  <span>请先粘贴链接提取文章内容</span>
`;
function _copy(text) {
  if (!text) return Toast("暂无文章内容");
  toClipboard(text)
    .then(() => {
      Toast("已成功复制到剪切板");
    })
    .catch(() => {
      Toast("复制失败");
    });
}
function getCopy() {
  if (!urlValue.value) return Toast("请输入链接地址");
  loading.value = true;
  geCopyContent({ url: urlValue.value })
    .then((res) => {
      const { content, url } = res;
      if (content) {
        Toast("提取文章成功");
        textValue.value = content;
        zhihuUrl.value = undefined;
      } else {
        Toast("请访问知乎内容页复制");
        textValue.value = undefined;
        zhihuUrl.value = url;
      }
      // const textarea = document.createElement("textarea");
      // const currentFocus = document.activeElement;
      // document.body.appendChild(textarea);
      // textarea.value = res;
      // textarea.focus();
      // if (textarea.setSelectionRange)
      //     textarea.setSelectionRange(0, textarea.value.length);
      // else
      //     textarea.select();
      // try {
      //     document.execCommand("copy");
      // } catch(eo){
      //     Toast('复制失败')
      // }
      // document.body.removeChild(textarea);
      // currentFocus.focus();
      // this.$copyText(res)
      // .then(() => {
      //   Toast('已成功复制到剪切板')
      // })
      // .catch(() => {
      //   Toast('复制失败')
      // })
      // const clipboardItemOut = new ClipboardItem({
      //   "text/plain": new Blob([res], { type: 'text/plain' })
      // })
      // navigator.clipboard.write([clipboardItemOut]).then(res => {
      //   alert(res)
      // }).catch((err) => {
      //   alert(err)
      // })
      // Toast('复制成功')
    })
    .finally(() => (loading.value = false));
}
function toZhihu() {
  window.location.href = zhihuUrl.value;
}
</script>
<style scoped lang="less">
.wrap {
  padding: 20px 20px 65px 20px;
}
.tip {
  background-color: #ff784c;
  display: flex;
  align-items: center;
  height: 52px;
  font-size: 18px;
  padding: 0 20px;
  color: #fff;
  margin-bottom: 40px;
}
.platform {
  font-size: 26px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 40px;
}
.btn-wrap {
  display: flex;
  margin-bottom: 20px;
}
.book-content {
  white-space: pre-line;
  text-align: left;
  padding: 20px;
  background-color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  /deep/ span {
    color: #999;
    padding: 6px 0;
  }
}
.link {
  padding: 15px 0 25px;
  p {
    color: #fd846f;
    font-size: 28px;
  }
}
</style>
